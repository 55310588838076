/* eslint no-new: 0 */
import gmaps from 'google-maps';
import $ from 'jquery';
import defaultStyle from './includes/map-style-default';
import largeStyle from './includes/map-style-large';

const styles = {
    default: defaultStyle,
    large: largeStyle,
};

export default function initGmaps (selector = '#map', APIKey = undefined) {
    const el = $(selector)[0];
    if (!el) return;

    gmaps.VERSION = '3.51';

    if (APIKey) {
        gmaps.KEY = APIKey;
    }

    const [lat, lng] = $(el).data('latlng').split(',').map(val => parseFloat(val));
    const latlng = {lat, lng};
    const zoom = $(el).data('zoom') ? $(el).data('zoom') : 6;
    const pin = $(el).data('pin') ? $(el).data('pin') : '/assets/img/map-pin.png';
    const style = $(el).data('style') in styles ? styles[$(el).data('style')] : styles.default;

    gmaps.load(google => {

        const resizeIcon = (url, opts) => {
            const { width, height } = opts;
            return {
                url,
                scaledSize: new google.maps.Size(width, height),
            };
        };

        const opts = {
            center: new google.maps.LatLng(latlng),
            zoom: zoom,
            zoomControl: true,
            zoomControlOptions: {
                style: google.maps.ZoomControlStyle.SMALL,
            },
            disableDoubleClickZoom: true,
            mapTypeControl: true,
            mapTypeControlOptions: {
                style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
            },
            scaleControl: false,
            scrollwheel: false,
            panControl: false,
            streetViewControl: true,
            draggable : true,
            overviewMapControl: true,
            overviewMapControlOptions: {
                opened: false,
            },
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            styles: style,
        };
        const locations = $('.js-centre')
            .map(function() {
                const [lat, lng] = $(this).data('latlng').split(',').map(val => parseFloat(val));
                const latlng = {lat, lng};
                return {
                    title: $(this).data('title'),
                    desc: '',
                    tel: '',
                    email: '',
                    web: $(this).data('url'),
                    position: latlng,
                    icon: pin,
                };
            })
            .get();

        const map = new google.maps.Map(el, opts);

        const markers = locations.map(location => {
            const marker = new google.maps.Marker({
                icon: location.icon,
                position: location.position,
                map: map,
                title: location.title,
                desc: location.desc,
                tel: location.tel,
                email: location.email,
                web: location.web,
            });
            bindInfoWindow(marker, map);
            return marker;
        });

        if (markers.length < 2) {
            return;
        }

        const bounds = new google.maps.LatLngBounds();
        markers.forEach(marker => {
            bounds.extend(marker.getPosition());
        });

        window.addEventListener('resize', () => {
            google.maps.event.trigger(map, 'resize');
            map.fitBounds(bounds);
        });

        map.fitBounds(bounds);

        markers.forEach(marker => {
            bounds.extend(marker.getPosition());
        });

    });

}


function bindInfoWindow(marker, map) {

    const infoWindowVisible = (function () {
        const currentlyVisible = false;
        return function (visible) {
            if (visible !== undefined) {
                const currentlyVisible = visible;
            }
            return currentlyVisible;
        };
    }());

    const iw = new google.maps.InfoWindow();

    google.maps.event.addListener(marker, 'click', function() {
        if (infoWindowVisible()) {
            iw.close();
            infoWindowVisible(false);
        } else {
            const title = marker.web ? '<a href="' + marker.web + '">' + marker.title + '</a>' : marker.title;
            const html= '<div style="color:#000;background-color:#fff;padding:5px;width:150px;"><h4>' + title + '</h4></div>';
            const iw = new google.maps.InfoWindow({content:html});
            iw.open(map,marker);
            infoWindowVisible(true);
        }
    });

    google.maps.event.addListener(iw, 'closeclick', function () {
        infoWindowVisible(false);
    });
}

