import $ from 'jquery';

export default function sNav (trigger, triggerActive, target, targetActive, parent, parentActive) {
    $(trigger).on('click', function(e) {
		e.preventDefault();
		$(this).toggleClass(triggerActive);
		$(parent).toggleClass(parentActive);
		$(target).toggleClass(targetActive);
	});
}
