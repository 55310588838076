import $ from 'jquery';

import skipLinks from './utils/skipLinks';
import widont from './utils/widont';
import iframer from './utils/iframer';
import mNav from './utils/mNav';
import sNav from './utils/sNav';
import initGmaps from './utils/gmaps';
import linkSwitch from './utils/linkswitcher';
import equalHeight from './utils/equalHeight';

function globals () {

     // Widow control
    widont('.widont, .widont p, .widont li');

    // iframe video in body content
    iframer();

    // Small Screen Navigation
    mNav(
       '#navigation-tertiary-toggle',
       'navigation-tertiary-toggle--active',
       '#navigation-tertiary__list',
       'navigation-tertiary__list--open'
    );

    mNav(
       '#navigation-trigger',
       'navigation-trigger--active',
       '#header__navigation',
       'header__navigation--open'
    );

    // Search bar
    sNav(
	    '#trigger-search',
	    'trigger-search--active',
	    '#header__utl',
	    'header__utl--open',
	    '#header',
	    'header--open'
    )

    // gmaps
    initGmaps('#map', 'AIzaSyBo9-VxCTrg-Kf7N0wVfdlWe0_TRTr-PuU');

    // Link switcher
    linkSwitch();

    if (!Modernizr.supports || !Modernizr.flexwrap) {
      equalHeight('.l-panels', '.l-panels__panel');
      equalHeight('.l-cards', '.card');
    }

}

$(function run () {
    console.log('ᕕ( ᐛ )ᕗ Running...');
    globals();
});
