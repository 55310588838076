const style = [
    {
        "featureType":"administrative",
        "elementType":"labels",
        "stylers":[
            {
                "visibility":"on"
            }
        ]
    },
    {
        "featureType":"administrative",
        "elementType":"labels.text.fill",
        "stylers":[
            {
                "color":"#ffffff"
            },
            {
                "weight":"0.01"
            }
        ]
    },
    {
        "featureType":"administrative",
        "elementType":"labels.text.stroke",
        "stylers":[
            {
                "visibility":"off"
            }
        ]
    },
    {
        "featureType":"landscape",
        "elementType":"all",
        "stylers":[
            {
                "color":"#09b275"
            }
        ]
    },
    {
        "featureType":"poi",
        "elementType":"all",
        "stylers":[
            {
                "visibility":"off"
            }
        ]
    },
    {
        "featureType":"road",
        "elementType":"all",
        "stylers":[
            {
                "saturation":-100
            },
            {
                "lightness":"100"
            },
            {
                "color":"#0f8f61"
            }
        ]
    },
    {
        "featureType":"road",
        "elementType":"geometry.stroke",
        "stylers":[
            {
                "visibility":"off"
            }
        ]
    },
    {
        "featureType":"road",
        "elementType":"labels.text.fill",
        "stylers":[
            {
                "color":"#ffffff"
            },
            {
                "weight":"1.49"
            }
        ]
    },
    {
        "featureType":"road",
        "elementType":"labels.text.stroke",
        "stylers":[
            {
                "color":"#ffffff"
            },
            {
                "visibility":"off"
            }
        ]
    },
    {
        "featureType":"road.highway",
        "elementType":"geometry.stroke",
        "stylers":[
            {
                "gamma":"6.20"
            },
            {
                "saturation":"30"
            }
        ]
    },
    {
        "featureType":"road.highway",
        "elementType":"labels",
        "stylers":[
            {
                "visibility":"on"
            }
        ]
    },
    {
        "featureType":"road.highway",
        "elementType":"labels.text",
        "stylers":[
            {
                "visibility":"simplified"
            },
            {
                "invert_lightness":true
            }
        ]
    },
    {
        "featureType":"road.highway",
        "elementType":"labels.text.stroke",
        "stylers":[
            {
                "visibility":"on"
            },
            {
                "saturation":"52"
            },
            {
                "lightness":"10"
            },
            {
                "weight":"6.63"
            },
            {
                "invert_lightness":true
            }
        ]
    },
    {
        "featureType":"road.highway",
        "elementType":"labels.icon",
        "stylers":[
            {
                "visibility":"off"
            }
        ]
    },
    {
        "featureType":"road.arterial",
        "elementType":"labels.icon",
        "stylers":[
            {
                "visibility":"off"
            }
        ]
    },
    {
        "featureType":"transit",
        "elementType":"all",
        "stylers":[
            {
                "visibility":"off"
            }
        ]
    },
    {
        "featureType":"water",
        "elementType":"all",
        "stylers":[
            {
                "color":"#a7dcec"
            },
            {
                "visibility":"on"
            },
            {
                "lightness":"-2"
            }
        ]
    }
]

export default style;
