import $ from 'jquery';

export default function linkswitcher () {

    // return if we don't support matchMedia
    if (!window.matchMedia) return;

    // select a tags with switching attrs
    const links = $('a[data-wide-href][data-narrow-href][data-switch-id][data-switch-max]');

    // switch on load
    switchLinks(links);

    // switch on resize
    $(window).resize(function() {
        switchLinks(links);
    });
}

function switchLinks(elems) {

    elems.each(function() {

        // get all the vars
        const elem = $(this);
        const wideHref = elem.data('wide-href');
        const narrowHref = elem.data('narrow-href');
        const switchId = elem.data('switch-id');
        const switchMax = elem.data('switch-max');

        // if less than the max, switch to narrow
        if (!window.matchMedia('(min-width: ' +  switchMax + 'px').matches) {
            elem.removeAttr('id');
            elem.attr('href', narrowHref);
        }

        // otherwise add the softscroll id and anchor
        else {
            elem.attr('id', switchId);
            elem.attr('href', wideHref);
        }
    });

}
